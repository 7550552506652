.Container {
  display: flex;
  margin-top: 1rem;
}
    
.InfoContainer {
  width: 20rem;
  padding: 1rem 1.5rem;
}
    
.InfoTitle {
  font-size: x-large;
  text-transform: capitalize;
  font-weight: bold;
  color: var(--text);
  margin: 0;
}
    
.InfoSubTitle {
  font-weight: bold;
  color: var(--text);
  font-size: small;
}
  
.InfoSubTitle::first-letter {
  text-transform: uppercase;
}
    
.Info {
  margin-top: 1rem;
  margin-bottom: .25rem;
}

.InfoText {
  color: var(--text);
  margin-top: 0;
  margin-bottom: .5rem;
  font-size: small;
}

.RelationsContainer {
  width: calc(100% - 20rem);
  padding: 1rem;
}

.Box {
  background-color: white;
  border-radius: 1rem;
  padding: 1rem;
  box-shadow: var(--box-shadow);
  min-height: 10rem;
}

.RelationBox {
  display: grid;
  margin: .5rem;
  grid-template-rows: 100%;
  /*grid-template-columns: repeat(3, calc(100%/3));*/
  grid-template-columns: repeat(3, calc( (100% - 2.5rem) / 3 )) 2.5rem;
}

.DropdownColumn {
}

.DropdownColumn:nth-child(1) {
  margin: 0 2rem 0 0;
}

.DropdownColumn:nth-child(2) {
  margin: 0 1rem;
}

.DropdownColumn:nth-child(3) {
  margin: 0 0 0 2rem;
}

.IconContainer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
} 

.DeleteRelationIcon {
  width: 1.5rem;
  height: 1.5rem;
  margin-bottom: .25rem;
  cursor: pointer;
} 

.AddRelationIcon {
  margin: 0;
  font-weight: bold;
} 

.AddRelationIcon.Disabled {
  cursor: not-allowed;
  color: var(--text);
  opacity: 0.5;
} 

.AddRelationIcon.Allowed {
  cursor: pointer;
  color: var(--primary);
} 