.Wrapper {
  width: 100%;
  background-color: #f6f6f6;
  min-height: 100%;
}

.MainContainer {
  display: flex;
}

.AddContainer {
  width: calc(100% - 7.5rem);
}

.SaveAndOrExitButtonsContainer {
  margin: 1rem 0;
}

.SpansContainer {
  width: 7.5rem;
}

.SpansBox {
  /*background-color: #ffffff;*/
  margin: 1rem;
  position: -webkit-sticky;
  position: sticky;
  top: 1rem;
  border-radius: 1.5rem;
  /*box-shadow: var(--box-shadow);*/
  padding: .5rem .75rem;
  
  background: rgba(255, 255, 255, 0.6);
  box-shadow: 0px 4px 32px rgba(0, 0, 0, 0.08);
  backdrop-filter: blur(64px);
}

.Span {
  display: flex;
  flex-direction: column;
  margin: .25rem auto;
  cursor: pointer;
}

.Span:not(:last-child) {
  border-bottom: 2px solid #e5e5e5;
}

.SpanIcon {
  margin: .5rem auto .25rem auto;
}

.SpanText {
  margin: .25rem auto .5rem auto;
  font-size: xx-small;
  font-weight: bold;
  text-transform: uppercase;
  color: var(--text-light);
}

.Active {
  color: var(--primary);
}

.LoadingContainer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.25);
  display: flex;
  justify-content: center;
  align-items: center;
}