.Toast {
  position: fixed;
  top: 5vh;
  left: 50%;
  transform: translate(-50%, 0%);
  background-color: #FFFFFF;
  border: 2px solid var(--primary);
  border-radius: .5rem;
  padding: 1rem .5rem;
  z-index: 10000;
  color: var(--text);
}

.ToastVisible {
  -webkit-animation: .5s fade-in ease-in-out;
  animation: .5s fade-in ease-in-out;
}

.ToastNonVisible {
  -webkit-animation: .5s fade-out ease-in-out;
  animation: .5s fade-out ease-in-out;
}

.ToastText {
  margin: 0;
  font-weight: bold;
}


  
@-webkit-keyframes fade-in {
  0% {
    opacity: 0;
    transform: translate(-50%, 0%) scale(0);
    transform-origin: center;
  }
  100% {
    opacity: 1;
    transform: translate(-50%, 0%) scale(1);
    transform-origin: center;
  }
}
  
@keyframes fade-in {
  0% {
    opacity: 0;
    transform: translate(-50%, 0%) scale(0);
    transform-origin: center;
  }
  100% {
    opacity: 1;
    transform: translate(-50%, 0%) scale(1);
    transform-origin: center;
  }
}

@-webkit-keyframes fade-out {
  0% {
    opacity: 1;
    transform: translate(-50%, 0%) scale(1);
    transform-origin: center;
  }
  100% {
    opacity: 0;
    transform: translate(-50%, 0%) scale(0);
    transform-origin: center;
  }
}
  
@keyframes fade-out {
  0% {
    opacity: 1;
    transform: translate(-50%, 0%) scale(1);
    transform-origin: center;
  }
  100% {
    opacity: 0;
    transform: translate(-50%, 0%) scale(0);
    transform-origin: center;
  }
}