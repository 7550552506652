/* Font Customization */

/* thin (100) */
@font-face {
  font-family: "Gotham";
  src: url("./assets/font/Gotham-Thin.otf") format("opentype");
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: "Gotham";
  src: url("./assets/font/Gotham-ThinItalic.otf") format("opentype");
  font-weight: 100;
  font-style: italic;
}

/* extra-light (200) */
@font-face {
  font-family: "Gotham";
  src: url("./assets/font/Gotham-XLight.otf") format("opentype");
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: "Gotham";
  src: url("./assets/font/Gotham-XLightItalic.otf") format("opentype");
  font-weight: 200;
  font-style: italic;
}

/* light (300) */
@font-face {
  font-family: "Gotham";
  src: url("./assets/font/Gotham-Light.otf") format("opentype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Gotham";
  src: url("./assets/font/Gotham-LightItalic.otf") format("opentype");
  font-weight: 300;
  font-style: italic;
}

/* book (400 - normal) */
@font-face {
  font-family: "Gotham";
  src: url("./assets/font/Gotham-Book.otf") format("opentype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Gotham";
  src: url("./assets/font/Gotham-BookItalic.otf") format("opentype");
  font-weight: 400;
  font-style: italic;
}

/* medium (500) */
@font-face {
  font-family: "Gotham";
  src: url("./assets/font/Gotham-Medium.otf") format("opentype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Gotham";
  src: url("./assets/font/Gotham-MediumItalic.otf") format("opentype");
  font-weight: 500;
  font-style: italic;
}

/* bold (700 - bold) */
@font-face {
  font-family: "Gotham";
  src: url("./assets/font/Gotham-Bold.otf") format("opentype");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Gotham";
  src: url("./assets/font/Gotham-BoldItalic.otf") format("opentype");
  font-weight: 700;
  font-style: italic;
}

/* black (800) */
@font-face {
  font-family: "Gotham";
  src: url("./assets/font/Gotham-Black.otf") format("opentype");
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: "Gotham";
  src: url("./assets/font/Gotham-BlackItalic.otf") format("opentype");
  font-weight: 800;
  font-style: italic;
}

/* ultra (900) */
@font-face {
  font-family: "Gotham";
  src: url("./assets/font/Gotham-Ultra.otf") format("opentype");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: "Gotham";
  src: url("./assets/font/Gotham-UltraItalic.otf") format("opentype");
  font-weight: 900;
  font-style: italic;
}


body {
  margin: 0;
  font-family: "Gotham" /*-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif*/;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overscroll-behavior-x: none; /* avoid swipe to go back in Chrome, Firefox, Edge */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

*, *:before, *:after {
  box-sizing: border-box;
}

/* Leaflet customization */
.leaflet-popup-content-wrapper,
.leaflet-popup-tip {
  background-color: rgba(255, 255, 255, .75)!important;
}

:root {
  --box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  --dropdown-area-z-index: 100;
  
  --popup-modal-z-index: 110;
  --toast-z-index: 1010;
  --loading-container-z-index: 1000;
  
  --topbar-height: 10vh;
  
  --primary: #F81A5C; /* rgb(248, 26, 92) */
  --primary-light: #fcdbe6;
  --primary-opaque: rgb(248, 26, 92, .5);
  --secondary: #f4f4f4;
  --secondary-light: #fbfbfb;
  --secondary-opaque: rgba(244, 244, 244, .5);

  --popup-modal: #FFFFFF;

  --text: #555659;
  --text-light: #a1a3a4;
  --text-white: #FFFFFF;

}

input:focus, textarea:focus {
  outline: var(--primary) solid 1px;
}

textarea, button, input {
  font-family: "Gotham";
}