.Wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Node {
  position: relative;
  stroke: none !important; 
}