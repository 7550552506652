.DropdownContainer {
  position: relative;
}

.ButtonContainer {
  display: flex;
  background-color: var(--secondary);
  color: var(--text);
  padding: .5rem 1rem;
  cursor: pointer;
}

.Placeholder {
  color: var(--text-light) !important;
}

.Outlined {
  border-left: 1px solid var(--secondary);
  border-right: 1px solid var(--secondary);
  border-top: 1px solid var(--secondary);
  background-color: transparent;
}

.Closed.Outlined {
  border-bottom: 1px solid var(--secondary);
}

.Closed {
  border-radius: .5rem;
}

.Open {
  border-top-left-radius: .5rem;
  border-top-right-radius: .5rem;
}

.DropdownValue {
  margin: 0 auto 0 0;
  font-size: smaller;
  overflow: hidden;
  white-space: pre;
  text-overflow: ellipsis;
  padding-right: .25rem;
}

.DropdownIcon {
  width: 1rem;
  height: 1rem;
}

.DropdownIconClosed {
  transform: rotateX(0deg);
}

.DropdownIconOpen {
  transform: rotateX(180deg);
}

.DropdownArea {
  position: absolute;
  top: calc(100%);
  width: 100%;
  background-color: #FFFFFF;
  z-index: var(--dropdown-area-z-index);
  border-bottom-left-radius: .5rem;
  border-bottom-right-radius: .5rem;
  overflow-y: auto;
  max-height: 10rem;
  border-left: 1px solid var(--secondary);
  border-right: 1px solid var(--secondary);
  border-bottom: 1px solid var(--secondary);
}

.DropdownSelection {
  font-size: smaller;
  padding: .5rem 1rem;
  margin: 0;
  cursor: pointer;
  color: var(--text);
  overflow: hidden;
  white-space: pre;
  text-overflow: ellipsis;
}

.DropdownSelection:hover {
  background-color: var(--secondary);
}

.NoSelection {
  pointer-events: none;
  color: var(--text-light);
}

.FilterContainer {
  margin: 0;
  padding: .5rem;
}

.FilterInput {
  height: 2rem;
  border: 1px solid rgba(0,0,0,0.25);
  border-radius: .5rem;
  background-color: #ffffff;
  display: flex;
  width: 100%;
  padding-left: 1rem;
  padding-right: 1rem;
}

.FilterInput:focus {
  outline: none !important;
  border: 1px solid black;
}

