.Wrapper {
  width: 100%;
  height: 100%;
  position: relative;
  background-color: rgba(59, 68, 91, 0.8);
  background-blend-mode: multiply;
}

.NodeContainer {
  position: relative;
  height: 100%;
  border-radius: inherit;
  display: flex;
  flex-direction: column;
}

/*.NodeContainer[data-hover=true] .Label {
  color: var(--text);
}

.NodeContainer[data-hover=true] .Text {
  color: var(--primary);
  font-weight: bold;
}*/

.Label {
  margin: 1rem auto .5rem auto;
  width: calc(100% - 2 * 1rem);
  font-weight: bold;
  font-size: smaller;
  color: #adadb0;
  text-transform: uppercase;
}

.Text {
  line-height: 1.35rem;
  margin: 0 auto 1rem auto; 
  width: calc(100% - 2 * 1rem); 
  max-height: calc(10 * 1.35rem); /*--- 10 * lineHeight ---*/
  overflow: hidden;
  color: #FFFFFF;
}

.RegularText {
  font-style: normal;
}

.ItalicText {
  font-style: italic;
}

.OverflowText::after {
  content: '';
  display: block;
  position: absolute;
  right: 1rem;
  bottom: 0.25rem;
  left: 1rem;
  height: calc(6 * 1.35rem); /*--- 6 * lineHeight ---*/
  background: linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%);
}

.DetailsContainer {
  position: absolute;
  left: 2rem;
  top: 10rem;
  width: 30%;
  max-height: 50%;
  background-color: rgba(85, 86, 89, 0.75);
  border-radius: 1rem;
  box-shadow: var(--box-shadow);
  padding: 1rem;
  overflow-y: auto;
}

.KnowledgeItemIntestationRow {
  display: flex;
  justify-content: space-between;
}

.KnowledgeItemLabel {
  margin: 0;
  font-size: x-small;
  color: var(--text-light);
  text-transform: uppercase;
  font-weight: bold;
}

.CloseDetailsContainerIcon {
  width: 1rem;
  height: 1rem;
  cursor: pointer;
}

.KnowledgeItemTitle {
  margin-top: .5rem;
  font-size: larger;
  color: #FFFFFF;
  font-weight: bold;
}

.KnowledgeItemNarrationsContainer {
  margin-top: 1.5rem;
}

.KnowledgeItemNarrationIntestation {
  margin: 0;
  font-size: small;
  color: #FFFFFF;
  text-transform: capitalize;
}

.KnowledgeItemNarrationTitle {
  margin: 0;
  font-size: small;
  color: var(--primary);
  font-weight: bold;
}

.KnowledgeItemRelationsContainer {
  margin-top: 1.5rem;
}

.KnowledgeItemRelationIntestation {
  margin: 0;
  font-size: small;
  color: #FFFFFF;
  text-transform: capitalize;
  font-weight: bold;
}

.KnowledgeItemRelationRow {
  display: flex;
  margin-top: .25rem;
}

.KnowledgeItemRelationType{
  margin: 0;
  font-size: small;
  color: var(--text-light);
  text-transform: capitalize;
  display: flex;
}

.KnowledgeItemRelationTitle {
  display: flex;
  margin: 0;
  font-size: small;
  color: var(--primary);
  font-weight: bold;
  padding-left: .25rem;
}


