.ForeignObject {
  pointer-events: none;
  border-radius: 1rem;
  display: flex;
}

.NodeContainer {
  position: relative;
  height: 100%;
  border-radius: inherit;
  display: flex;
  flex-direction: column;
}

/*.Label {
  margin: 1rem auto .5rem auto;
  width: calc(100% - 2 * 1rem);
  font-weight: bold;
  font-size: smaller;
  color: #adadb0;
  text-transform: uppercase;
}*/

.NodeContainer[data-hover=true] .Label {
  color: var(--text);
}

/*.Text {
  line-height: 1.35rem;
  margin: 0 auto 1rem auto; 
  width: calc(100% - 2 * 1rem); 
  max-height: calc(10 * 1.35rem); /*--- 10 * lineHeight ---
  overflow: hidden;
}*/

.NodeContainer[data-hover=true] .Text {
  color: var(--primary);
  font-weight: bold;
}

.OverflowText::after {
  content: '';
  display: block;
  position: absolute;
  right: 1rem;
  bottom: 0.25rem;
  left: 1rem;
  height: calc(6 * 1.35rem); /*--- 6 * lineHeight ---*/
  background: linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%);
}