.Container {
  margin: 0 .5rem 1rem 0;
  display: flex;
  position: relative;
}

.SourceBox {
  display: flex;
  box-shadow: var(--box-shadow);
  background-color: #FFFFFF;
  border-radius: 1rem;
}

.InfoColumn {
  display: flex;
  flex-direction: column;
  padding: 1rem 0 1rem 1rem;
}

.InfoColumnWithRightPadding {
  padding-right: 1rem;
}

.SourceTypeRow {
  margin-bottom: .5rem;
}

.DropdownContainer {
  border-radius: .5rem;
}

.TitleRow {
  margin: .5rem 0;
}

.TitleTextArea {
  background-color: var(--secondary);
  width: 100%;
  border: none;
  border-radius: .25rem;
  padding: .5rem 1rem;
  display: flex;
  resize: none;
}

.InputError {
  transition: outline .05s ease-in-out;
  outline: black solid 2px;
}

.TitleTextArea::placeholder {
  color: var(--text-light);
}

.AddMediaRow {
  margin-top: .5rem;
  display: flex;
}

.ClickableRowArea {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.AddMediaIcon {
  width: 1rem;
  height: 1rem;
}

.AddMediaText {
  font-size: smaller;
  color: var(--primary);
  font-weight: bold;
  margin: 0;
  padding: 0 .5rem;
}

.DeleteSourceRowWithoutMediaMargin {
  margin-top: 1rem;
  display: flex;
}

.DeleteSourceRowWithMediaMargin {
  margin-top: auto;
  display: flex;
}

.DeleteSourceIcon {
  width: 1.25rem;
  height: 1.25rem;
  margin-left: -.125rem
}

.DeleteSourceText {
  font-size: smaller;
  color: var(--primary);
  font-weight: bold;
  margin: 0; 
  margin-left: -.125rem;
  padding: 0 .5rem;
}

.MediaColumn {
  display: flex;
  flex-wrap: wrap;
  padding: .5rem;
}

.MediaContainer {
  margin: .5rem;
  width: 250px;
}

.DisableBoxContainer {
  display: flex;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  position: absolute;
  border-radius: 1rem;
  cursor: not-allowed;
}