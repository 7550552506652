.LoaderBox {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.3s color, 0.3s border, 0.3s transform, 0.3s opacity;
}
  
.Loader {
  display: inline-block;
  width: 1em;
  height: 1em;
  color: inherit;
  vertical-align: middle;
  pointer-events: none;
  border: 0.2em solid transparent;
  border-top-color: currentcolor;
  border-radius: 50%;
  -webkit-animation: 1s loader linear infinite;
  animation: 1s loader linear infinite;
  position: relative;
}

.Loader:before {
    content: "";
    display: block;
    width: inherit;
    height: inherit;
    position: absolute;
    top: -0.2em;
    left: -0.2em;
    border: 0.2em solid currentcolor;
    border-radius: 50%;
    opacity: 0.5;
  }
  
@-webkit-keyframes loader {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
  
@keyframes loader {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}