.PopupBackground {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: var(--popup-modal-z-index);
  background-color: rgba(0,0,0,.25);
  display: flex;
  justify-content: center;
  align-items: center;
}

.PopupContainer {
  background-color: var(--popup-modal);
  border-radius: 1rem;
  padding: .5rem 1rem;
}