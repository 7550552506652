.AuthorContainer {
  display: flex;
  padding-right: 2rem;
}

.Buttons {
  font-size: 1rem;
  font-weight: bold;
}

.Buttons::first-letter {
  text-transform: uppercase;
}

.ExitWithoutSaving {
  margin: auto 2rem auto 0;
  color: var(--text);
  cursor: pointer;
}


.Save {
  margin: auto 2rem auto 0;
  color: var(--text-white);
  padding: .5rem 2rem;
  border-radius: 1.25rem;
  background-color: var(--primary);
  cursor: pointer;
}

.SaveAndQuit {
  margin: auto 0;
  color: var(--text-white);
  padding: .5rem 2rem;
  border-radius: 1.25rem;
  background-color: var(--primary);
  cursor: pointer;
}

.SaveAndQuit.IsEdit {
  margin-right: 0;
  background-color: aqua;
}

.Delete {
  display: flex;
  cursor: pointer;
  border-radius: 1.25rem;
  padding: .25rem 1rem;
  margin: auto 2rem auto auto;
}

.Delete:hover {
  outline: 1px solid var(--primary);
}

.DeleteText {
  margin: auto 0;
  color: var(--text);
  text-transform: capitalize;
}

.DeleteIcon {
  margin: auto 0 auto .5rem;
  height: 1.5rem;
}

.PopupText {
  color: var(--text);
  text-align: center;
  white-space: pre-line;
}

.PopupText::first-letter {
  text-transform: uppercase;
}

.PopupConfirmContainer {
  display: flex;
  justify-content: space-around;
}

.PopupChoice {
  cursor: pointer;
  border-radius: 1rem;
  padding: .5rem 1rem;
  font-weight: bold;
  text-transform: uppercase;
  min-width: 30%;
  text-align: center;
  background-color:  var(--secondary);
  color: var(--text);
}

.PopupChoice:hover {
  background-color:  var(--primary);
  color: var(--text-white);
}