.Container {
  display: flex;
}

.InfoContainer {
  width: 20rem;
  padding: 1rem 1.5rem;
}

.InfoTitle {
  font-size: x-large;
  text-transform: capitalize;
  font-weight: bold;
  color: var(--text);
  margin: 0;
}

.InfoSubTitle {
  font-weight: bold;
  color: var(--text);
  font-size: small;
}

.InfoSubTitle::first-letter {
  text-transform: uppercase;
}

.Info {
  margin-top: 1rem;
  margin-bottom: .25rem;
}

.Fragment {
  margin-top: 3rem;
  margin-bottom: .25rem;
}

.InfoText {
  color: var(--text);
  margin-top: 0;
  margin-bottom: .5rem;
  font-size: small;
}


.BoxesContainer {
  width: calc(100% - 20rem);
  padding: 1rem;
}

.Box {
  background-color: white;
  border-radius: 1rem;
  padding: 1rem;
  box-shadow: var(--box-shadow);
}

.TitleAuthorLocationBox {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 1.5rem;
}

.Input {
  background-color: var(--secondary);
  border: none;
  padding: 1rem;
  border-radius: .5rem;
  display: flex;
}

.Input::placeholder {
  text-transform: capitalize;
  color: var(--text-light);
}

.TitleInput {
  width: 100%;
  margin-bottom: .5rem;
}

.AuthorInput {
  flex: 1;
}

.InputError {
  transition: outline .05s ease-in-out;
  outline: black solid 2px;
}

.FragmentsBox {
  display: flex;
  flex-direction: column;
}