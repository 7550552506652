.Wrapper {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
}

.NarrationContainer {
  background-color: #ffffff;
  border-radius: 1rem;
  box-shadow: var(--box-shadow);
  width: 100%;
  margin: 1rem .5rem 1rem 2rem;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  max-height: calc(100% - 2* 1rem);
}

.NarrationType {
  font-size: x-small;
  color: var(--text-light);
  text-transform: uppercase;
  font-weight: bold;
  margin: 0;
}

.NarrationTitle {
  font-size: larger;
  color: var(--text);
  font-weight: bold;
  margin: .5rem 0;
}

.NarrationFragmentsContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.NarrationFragment {
  margin: .25rem .25rem;
  background-color: #E5E5E5;
  color: var(--text);
  font-weight: bold;
  font-size: small;
  padding: .1rem .25rem;
  border-radius: .2rem;
  text-transform: capitalize;
  cursor: pointer;
}

.NarrationTextContainer {
  overflow-y: auto;
}

.NarrationText {
  margin: 0;
}

.NarrationText:first-child {
  margin-top: 1rem;
}

.NarrationText:last-child {
  margin-bottom: 1rem;
}
