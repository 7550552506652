.Container {
  box-shadow: var(--box-shadow);
  background-color: #FFFFFF;
  border-radius: 1rem;
  padding: .5rem;
}


.TitleRow {
  margin: .5rem 0;
  display: flex;
  align-items: center;
}

.TitleInput {
  background-color: var(--secondary);
  width: 100%;
  border: none;
  border-radius: .25rem;
  padding: .5rem 1rem;
  display: flex;
  resize: none;
}

.TitleInput::placeholder {
  color: var(--text-light);
}

.InputError {
  transition: outline .05s ease-in-out;
  outline: black solid 2px;
}

.RemoveMediaIcon {
  width: 1.25rem;
  height: 1.25rem;
  cursor: pointer;
  margin-left: .5rem;
}

.FileInputRow {
  margin: .5rem 0;
  display: flex;
  flex-direction: column;
}

.MediaInputContainer {
  position: relative;
  display: flex;
  height: 2rem;
  width: 100%;
}

.MediaTypeIcon {
  position: absolute;
  transform: translate(0, -50%);
  top: 50%;
  left: .5rem;
  width: 1rem;
  height: 1rem;
}

.MediaInput {
  border: none;
  border-radius: .5rem;
  background-color: #ffffff;
  display: flex;
  flex: 1;
  background-color: var(--secondary);
}

.MediaFileInput {
  overflow: hidden;
}

.FileSizeAlertText {
  font-size: x-small;
  color: red;
  margin: 0;
}

.MediaFileText {
  margin: auto 2rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: var(--text);
  font-size: smaller;
}

.DeleteMediaIcon {
  position: absolute;
  transform: translate(0%, -50%);
  top: 50%;
  right: .5rem;
  margin: 0;
  color: var(--primary);
  cursor: pointer;
  font-size: smaller;
  font-weight: bold;
  width: 1rem;
  text-align: center;
}

.AddMediaContainer {
  display: flex;
  width: 100%;
  min-height: 2rem;
}

.AddMediaText {
  display: flex;
  width: 100%;
  margin: auto 0;
  font-size: smaller;
  color: var(--primary);
  font-weight: bold;
  cursor: pointer;
}

.SetMediaFileIcon {
  width: 1rem;
  margin-right: .25rem;
  pointer-events: none;
}

.PreviewColumn {
  border: 1px solid var(--primary);
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: .5rem;
  height: 150px;
}

.PreviewImage {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.NoMediaAdded {
  display: flex;
  justify-content: center;
  align-items: center;
}

.NoMediaAddedText {
  margin: 1rem;
  font-size: smaller;
}

