.Container {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
}

.BoxContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: var(--topbar-height);
  width: 100%;
}

.LeftColumn {
  width: 35%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.RightColumn {
  width: 65%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 0 1rem;
}

.CustomNetworkWrapper {
  overflow: auto;
  padding: 0 0rem 15vh 1rem;
  flex: 1;
}

.NarrationIntestation {
  display: flex;
  margin-top: 1rem;
  padding-bottom: 2rem;
}

.IntestationFragment {
  background-color: #FFFFFF;
  border-radius: .75rem;
  box-shadow: var(--box-shadow);
  position: relative;
  margin: auto 0;
  padding: .5rem 1rem;
}

.IntestationFragment:nth-child(2) {
  margin-left: 2rem;
}

.IntestationFragmentLabel {
  margin: 0 0 .5rem 0;
  text-transform: uppercase;
  font-weight: bold;
  font-size: smaller;
  color: var(--text-light);
}

.IntestationFragmentText {
  margin: 0;
  overflow: hidden;
  font-size: small;
}

.NetworkContainer {
  display: inline-flex;
}

.GroupContainer:last-child {
  padding-right: 1rem;
}

.FragmentContainer {
  display: flex;
  position: relative;
  width: 100%;
}

.Fragment {
  background-color: #FFFFFF;
  border-radius: 1rem;
  box-shadow: var(--box-shadow);
  width: 300px;
  position: relative;
}

.HoveredFragment .Fragment {
  background-color: var(--primary-light);
}

.FragmentLabel {
  margin: 1rem 1rem;
  text-transform: uppercase;
  font-weight: bold;
  font-size: smaller;
  color: var(--text-light);
}

.FragmentText {
  line-height: 1.1em;
  margin: 0 auto 1rem auto; 
  width: calc(100% - 2 * 1rem);
  max-height: calc(10 * 1.1em); /*--- 10 * lineHeight ---*/
  overflow: hidden;
  font-size: small;
}

.HoveredFragment .OverflowFragmentText::after {
  background: linear-gradient(180deg, rgba(255,255,255,0) 0%, var(--primary-light) 100%);
}

.OverflowFragmentText::after {
  content: '';
  display: block;
  position: absolute;
  right: 1rem;
  bottom: 0.25rem;
  left: 1rem;
  height: calc(6 * 1.1em); /*--- 6 * lineHeight ---*/
  background: linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%);
}

.ArrowContainer {
  display: flex;
}

.ArrowLine {
  height: 1px;
  width: 100px;
  background-color: grey;
  margin: auto 0;
}

.ArrowMarker {
  width: 10px;
  height: 10px;
  margin: auto 0;
  display: flex;
}

.SourcesContainer {
}

.Source {
  display: flex;
  margin: 0 2rem;
}

.SourceArrow {
  width: 30px;
  display: flex;
}

.SourceArrowMiddle {
  display: flex;
}

.SourceArrowEnd {
  display: flex;
  height: 100%;
  width: 30px;
}

.SourceArrowMarker {
  width: 10px;
  height: 10px;
  margin: auto 0;
  display: flex;
}

.SourceBox {
  background-color: #FFFFFF;
  margin: 1rem 0;
  display: flex;
  flex-direction: column;
  max-width: 300px;
  border-radius: 1rem;
  box-shadow: var(--box-shadow);
  padding: 1rem;
}

.HoveredFragment .SourceBox {
  border: 1px solid var(--primary);
}

.SourceBoxLabel {
  margin: 0 0 .5rem 0;
  text-transform: uppercase;
  font-weight: bold;
  font-size: smaller;
  color: var(--text-light);
}

.SourceBoxText {
  line-height: 1.1em;
  margin: 0; 
  overflow: hidden;
  font-size: small;
}

.SourceBoxLink {
  line-height: 1.1em;
  margin: 0; 
  width: calc(100% - 2 * 1rem);
  word-wrap: break-word;
  font-size: small;
  color: var(--primary);
}