.BtnContainer {
  display: grid;
  align-items: center;
  position: relative;
  background-color: white;
  border-radius: 5rem;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
  
.Button {
  margin: 0;
  padding: .75rem 1.25rem;
  border-radius: 2.5rem;
  text-align: center;
  cursor: pointer;
  z-index: 1;
  font-weight: bold;
  font-size: smaller;
}

.ActiveBtn {
  position: absolute;
  transform: translate(-50%, -50%);
  left: 25%;
  top: 50%;
  height: calc(100% - 0.5rem);
  border-radius: 5rem;
  transition: left .2s ease-in-out;
}
