.Wrapper {
  display: flex;
  border: 1px solid var(--text);
  padding: .125rem .25rem;
  margin: .25rem 0;
}

.CoordinatesContainer {
  flex: 1;
  display: grid;
  grid-template-columns: auto 1fr;
  grid-template-rows: 1fr 1fr;
}

.Label{
  color: var(--text);
  font-weight: bold;
  font-size: smaller;
  margin: .25rem .25rem .25rem 0;
}

.Label::first-letter{
  text-transform: uppercase;
}

.Value {
  color: var(--text);
  font-size: smaller;
  margin: auto 0;
}

.IconsContainer {
  display: flex;
}

.Icon {
  width: 1.5rem;
  height: 1.5rem;
  cursor: pointer;
  margin: auto 0;
}

.EditIconText {
  margin: auto 0;
  font-size: 1.25rem;
  color: var(--primary);
  cursor: pointer;
}

.Icon:last-child,
.EditIconText:last-child {
  margin-left: .5rem;
}

.EditIconText:first-child {
  margin-left: .5rem;
}