.Wrapper {
  display: flex;
  height: 100vh;
  width: 100vw;
  background-color: rgba(0,0,0,0.001);
  background-image: -webkit-repeating-radial-gradient(top center, rgba(0,0,0,.1), rgba(0,0,0,.1) 1px, transparent 0, transparent 100%);
  background-size: 20px 20px;
}

.Container {
  margin: auto;
  background-color: var(--secondary-opaque);
  box-shadow: var(--box-shadow);
  border-radius: 1rem;
  padding: 1rem 1.5rem;
  min-width: 30vw;
}

.Title {
  margin: 0;
  font-weight: bold;
  font-size: 1.25rem;
  letter-spacing: .25rem;
  color: var(--text);
}

.LoginContainer {
}

.LoginRow {
  margin-top: 1rem;
}


.Label {
  font-size: smaller;
  color: var(--text);
  font-weight: bold;
}

.Label::first-letter {
  text-transform: uppercase;
}

.Input {
  background-color: #FFFFFF;
  border: none;
  padding: .75rem 1rem;
  border-radius: .5rem;
  display: flex;
  resize: vertical;
  width: 100%;
  margin-top: .5rem;
}

.Input::placeholder {
  color: var(--text-light);
}

.ButtonRow {
  display: flex;
  width: 100%;
}

.LoginButton {
  cursor: pointer;
  margin: 1.5rem auto .5rem auto;
  background-color: var(--primary);
  color: #FFFFFF;
  font-weight: bold;
  border-radius: 1rem;
  padding: .5rem;
  width: 60%;
  text-align: center;
  text-transform: uppercase;
}

.LoginButtonDisabled {
  pointer-events: none;
  background-color: var(--primary-light);
}