.Container {
  display: flex;
  margin-top: 1rem;
}
  
.InfoContainer {
  width: 20rem;
  padding: 1rem 1.5rem;
}
  
.InfoTitle {
  font-size: x-large;
  text-transform: capitalize;
  font-weight: bold;
  color: var(--text);
  margin: 0;
}
  
.InfoSubTitle {
  font-weight: bold;
  color: var(--text);
  font-size: small;
}

.InfoSubTitle::first-letter {
  text-transform: uppercase;
}
  
.Info {
  margin-top: 1rem;
  margin-bottom: .25rem;
}
  
.Fragment {
  margin-top: 3rem;
  margin-bottom: .25rem;
}
  
.InfoText {
  color: var(--text);
  margin-top: 0;
  margin-bottom: .5rem;
  font-size: small;
}

.EntitiesBtnContainer {
  display: flex;
  flex-wrap: wrap;
  margin-top: 2rem;
}

.EntityBtn {
  cursor: pointer;
  margin: .25rem .5rem .25rem 0;
  background-color: #E5E5E5;
  padding: .5rem 1rem;
  border-radius: 1rem;
  font-size: smaller;
  color: var(--text);
  font-weight: bold;
  text-transform: capitalize;
}

.EntityBtn:hover {
  box-shadow: var(--box-shadow);
}

.InsertedEntitiesContainer {
  margin-top: 2rem;
  margin-bottom: 1rem;
}
   
.BoxesContainer {
  width: calc(100% - 20rem);
  padding: 1rem;
}

.Box {
  background-color: #ffffff;
  border-radius: .5rem;
  box-shadow: var(--box-shadow);
  padding: 1rem;
  min-height: 20rem;
  display: flex;
  flex-direction: column;
}

.NoFragment {
  font-size: x-large;
  color: var(--text-light);
  margin: auto;
}

.NoFragment::first-letter {
  text-transform: uppercase;
}

.KnowledgeFragment {
}

.KnowledgeFragment:not(:last-child) {
  margin-bottom: 2rem;
}

.FragmentTitle {
  margin: 0 0 .5rem 0;
  font-size: smaller;
  font-weight: bold;
  color: var(--text-light);
  text-transform: uppercase;
}

.FragmentText {
  color: var(--text);
  margin: 0;
}

.FragmentText span::selection {
  background-color: rgba(60, 81, 92, 0.2);
  font-weight: bold !important;
}

.Mark {
  background-color: var(--primary-light);
  color: var(--primary);
  font-weight: bold;
  user-select: none;
  -webkit-user-select: none;
}

.HoveredMark {
  border: 2px solid var(--primary);
}

.PopupStep {
  margin: .5rem 0;
}

.PopupTitle {
  margin: 0;
  font-weight: bold;
}

.PopupTitle::first-letter {
  text-transform: uppercase;
}

.PopupTextArea {
  width: 100%;
  background-color: var(--secondary);
  border: none;
  padding: .75rem 1rem;
  border-radius: .5rem;
  display: flex;
  margin-top: 1rem;
  margin-bottom: .5rem;
  resize: vertical;
}

.PopupTextArea::placeholder {
  color: var(--text-light);
}

.SelectFragment {
  font-size: smaller;
  margin-top: 1rem;
  margin-bottom: .25rem;
}

.SelectFragment::first-letter {
  text-transform: uppercase;
}

.InsertedFragmentsNamesContainer {
  display: flex;
  flex-wrap: wrap;
}

.InsertedFragmentName {
  background-color: var(--secondary);
  margin: .25rem;
  font-size: small;
  font-weight: bold;
  padding: .25rem .5rem;
  display: flex;
  text-transform: capitalize;
  border-radius: .25rem;
  cursor: pointer;
}

.InsertedFragmentName:hover {
  box-shadow: var(--box-shadow);
}

.InsertedFragmentName.SelectedInsertedFragment {
  border: 2px solid black;
}

.PopupButtonsContainer {
  display: flex;
  margin-top: 1rem;
}

.PopupButton {
  margin: 0 auto;
  padding: .25rem .5rem;
  text-transform: uppercase;
  font-weight: bold;
  cursor: pointer;
  border: none;
  background-color: transparent;
  color: var(--text);
}

.PopupButton:disabled {
  cursor: not-allowed;
}

.PopupStep1 {
  margin-top: .5rem;
}

.PopupQuestion {
  margin: 0;
  color: var(--text);
}

.SimilarEntitiesContainer {
  display: flex;
  margin-top: 1rem;
  overflow: auto;
}

.SimilarEntityName {
  background-color: var(--secondary);
  margin: .25rem;
  font-size: small;
  padding: .25rem .5rem;
  display: flex;
  text-transform: capitalize;
  border-radius: .25rem;
  cursor: pointer;
  white-space: pre;
}

.SimilarEntityName:hover {
  box-shadow: var(--box-shadow);
}

.SimilarEntityName.SelectedSimilarEntity {
  border: 2px solid black;
}

.AlreadyInsertedEntity {
  opacity: .5;
  background-color: var(--secondary);
  margin: .25rem;
  font-size: small;
  padding: .25rem .5rem;
  display: flex;
  text-transform: capitalize;
  border-radius: .25rem;
  white-space: pre;
  cursor: default;
}

.PopupGrid {
  display: grid;
  grid-template-columns: auto 1fr;
  grid-template-rows: repeat(100%, 3);
  margin-top: .5rem;
}

.PopupTextLabel {
  margin: .5rem 2rem .5rem 0;
  text-transform: capitalize;
  font-weight: bold;
  font-size: smaller;
}

.PopupText {
  margin: .5rem 0;
  text-transform: capitalize;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;  
  overflow: hidden;
  font-size: smaller;
}

.PopupLocationContainer {
  margin-top: .5rem;
}

.AddLocationText {
  margin: .5rem .5rem .5rem 0;
  font-weight: bold;
  font-size: smaller;
}

.AddLocationText2 {
  margin: .5rem .5rem .5rem 0;
  font-size: smaller;
}

.AddLocationText::first-letter,
.AddLocationText2::first-letter {
  text-transform: uppercase;
}

.InsertedLocations {
  max-height: 20vh;
  overflow-y: auto;
  overscroll-behavior-y: contain;
}

.PopupMapContainer {
  height: 40vh;
  width: 100%;
  border: 1px solid var(--text);
  margin-top: .5rem;
}