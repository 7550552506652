.Container {
  background-color: #FFF;
  box-shadow: var(--box-shadow);
  border-radius: 1rem;
  padding: 1rem;
  width: 25vw;
  cursor: pointer;
}

.Container:hover {
  background-color: var(--primary-light);
}

.IntestationRow {
  display: flex;
  justify-content: space-between;
}

.NarrationLabel {
  margin: 0;
  font-size: x-small;
  color: var(--text-light);
  text-transform: uppercase;
  font-weight: bold;
}

.NarrationTitle {
  margin: .25rem 0 0 0;
  font-size: larger;
  color: var(--text);
  font-weight: bold;
  word-break: break-all;
}

.NarrationObjectContainer {
  margin-top: 1rem;
}

.NarrationObjectIntestation {
  margin: 0 0 .5rem 0;
  font-weight: bold;
  font-size: x-small;
  color: var(--text-light);
  text-transform: uppercase;
}

.ConnectedObjectRow {
  margin-top: .25rem;
}

.ConnectedObjectType {
  display: flex;
  margin: 0;
  font-size: small;
  color: var(--primary);
  font-weight: bold;
}

.ConnectedObjectText {
  margin: 0;
  font-size: small;
  color: var(--text-light);
  text-transform: capitalize;
  display: flex;
}


