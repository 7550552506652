.Wrapper {
  width: 100vw;
  height: 100vh;
  position: relative;
  background-color: rgba(0,0,0,0.001);
  background-image: -webkit-repeating-radial-gradient(top center, rgba(0,0,0,.1), rgba(0,0,0,.1) 1px, transparent 0, transparent 100%);
  background-size: 20px 20px;
}

.SearchContainer {
  position: absolute;
  top: var(--topbar-height);
  left: 0;
  /*width: 100%;*/
  max-height: calc(100% - var(--topbar-height));
  background: none;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 2.5rem 5rem;
  overflow-y: auto;
  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}


/* Hide scrollbar for Chrome, Safari and Opera */
.SearchContainer::-webkit-scrollbar {
  display: none;
}

.LoadingContainer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.25);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: var(--loading-container-z-index);
}