.Container {
  display: flex;
  margin-top: 1rem;
}

.InfoContainer {
  width: 20rem;
  padding: 1rem 1.5rem;
}

.InfoTitle {
  font-size: x-large;
  text-transform: capitalize;
  font-weight: bold;
  color: var(--text);
  margin: 0;
}
    
.InfoSubTitle {
  font-weight: bold;
  color: var(--text);
  font-size: small;
}
  
.InfoSubTitle::first-letter {
  text-transform: uppercase;
}
    
.Info {
  margin-top: 1rem;
  margin-bottom: .25rem;
}

.InfoText {
  color: var(--text);
  margin-top: 0;
  margin-bottom: .5rem;
  font-size: small;
}

.SourcesContainer {
  width: calc(100% - 20rem);
  padding: 1rem;
  display: flex;
  flex-wrap: wrap;
}

.AddSourceContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.AddSourceBox {
  cursor: pointer;
  border: 1px solid var(--text);
  border-radius: .5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1rem;
}

.AddSourceIcon {
  width: 7.5rem;
  height: 7.5rem;
}

.AddSourceText {
  color: var(--text);
  margin: 1rem 0 0 0;
  text-transform: capitalize;
}

