.Fragment {
  display: flex;
  flex-direction: column;
  border-radius: .5rem;
  padding: 1rem;
}

.OpenFragment {
  background-color: var(--secondary-light);
  border: 1px solid var(--primary);
}

.ClosedFragment {
  background-color: var(--secondary);
}

.Fragment:not(:last-child){
  margin-bottom: .25rem;
}

.HeaderContainer {
  display: flex;
}

.FragmentTitle {
  margin: 0;
  text-transform: uppercase;
  color: var(--text-light);
  font-size: smaller;
  font-weight: bold;
  cursor: pointer;
}

.OpenCloseIcon {
  width: 1rem;
  height: 1rem;
  margin-left: auto;
  cursor: pointer;
}

.OpenFragmentContainer {
  display: flex;
  flex-direction: column;
}

.Input {
  background-color: var(--secondary);
  border: none;
  padding: .75rem 1rem;
  border-radius: .5rem;
  display: flex;
  margin-top: .5rem;
  margin-bottom: .5rem;
  resize: vertical;
}

.Input::placeholder {
  color: var(--text-light);
}

.InputError {
  transition: outline .05s ease-in-out;
  outline: black solid 2px;
}

.SourceReferencesTitle {
  margin: 0;
  font-weight: bold;
  color: var(--text-light);
  font-size: small;
}

.SourceReferencesTitle::first-letter {
  text-transform: uppercase;
}

.SourceReferencesTextInfo {
  margin: 0;
  color: var(--text);
  font-size: small;
  margin-bottom: .25rem;
}

.SourceReferencesTextInfo span {
  margin: 0;
  color: var(--primary);
  cursor: pointer;
}

.SourceReferencesTextInfo span:hover {
  text-decoration: underline;
}

.SourceReferenceContainer {
  background-color: var(--secondary);
  border-radius: 1rem;
  padding: 1rem;
  display: flex;
}

.SourceReferenceContainer:not(:first-child) {
  margin-top: .5rem;
}

.SourceReferenceInfoColumn {
  width: 50%;
}

.SourceReferenceInput {
  height: 2rem;
  border: none;
  border-radius: .5rem;
  background-color: #ffffff;
  display: flex;
  width: 100%;
  padding-left: 1rem;
  padding-right: 1rem;
}

.SourceReferenceTextarea {
  border: none;
  border-radius: .5rem;
  background-color: #ffffff;
  display: flex;
  width: 100%;
  padding: .5rem 1rem;
  margin-top: .5rem;
  margin-bottom: .5rem;
}

.RemoveSourceReferenceContainer {
  display: flex;
  margin-top: auto;
}

.ClickableAreaRow {
  display: flex;
  cursor: pointer;
  align-items: center;
}

.RemoveSourceReferenceIcon {
  width: 1.5rem;
  height: 1.5rem;
  margin-right: .25rem;
}

.RemoveSourceReferenceText {
  margin: 0;
  font-size: smaller;
  color: var(--primary);
  font-weight: bold;
}

.AddSourcesColumn {
  display: flex;
  flex-direction: column;
  width: 50%;
  margin-left: .5rem;
  background-color: #FFFFFF;
  border-radius: .5rem;
  padding: .5rem 1rem;
}

.AddSourceRow {
  display: flex;
  align-items: center;
}

.AddSourceText {
  margin: 0;
  color: var(--text);
  font-size: smaller;
  font-weight: bold;
  text-transform: capitalize;
  margin-right: auto;
  margin-bottom: auto;
  margin-top: .25rem;
}

.AddSourceDropdown {

}


.AddSourceIcon {
  margin: 0;
  padding-left: .5rem;
  font-weight: bold;
} 

.AddSourceIcon.Disabled {
  cursor: not-allowed;
  color: var(--text);
  opacity: 0.5;
} 

.AddSourceIcon.Allowed {
  cursor: pointer;
  color: var(--primary);
} 

.AddedSources {
  display: flex;
  flex-direction: column;
}

.AddedSourceContainer {
  display: flex;
  align-items: center;
  margin-top: .5rem;
}

.AddedSourceText {
  margin: 0;
  color: var(--text);
  font-size: smaller;
}

.RemoveSourceIcon {
  margin-left: auto;
  width: 1.25rem;
  cursor: pointer;
}

.AddSourceReferenceContainer {

}

.AddSourceReferenceIcon {
  width: 1.25rem;
  height: 1.25rem;
  margin-right: .25rem;
}

.AddSourceReferenceText {
  color: var(--primary);
  font-size: smaller;
  font-weight: bold;
}



.DoneContainer {
  margin-top: .5rem;
  display: flex;
}

.ClickebleArea {
  display: flex;
  margin-left: auto;
  cursor: pointer;
  padding-left: .5rem;
}

.ClickebleArea p {
  color: var(--primary);
  font-size: smaller;
  font-weight: bold;
  margin: 0;
}

.ClickableAreaEditFragment {
  display: flex;
  margin-left: auto;
  padding-left: .5rem;
}

.CancelEditText {
  cursor: pointer;
  color: var(--primary);
  font-size: smaller;
  font-weight: bold;
  margin: 2px 1.5rem 0 0;
  cursor: pointer;
}

.ConfirmEditText {
  cursor: pointer;
  color: var(--primary);
  font-size: smaller;
  font-weight: bold;
  margin: 0;
}